import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  kategoriler: [],
};
const getters = {
  getKategoriler: (state) => state.kategoriler,
};
const mutations = {
  SET_KATEGORILER(state, payload) {
    state.kategoriler = payload;
  },
  SET_KATEGORILER_PUSH(state, payload) {
    state.kategoriler.push(payload);
  },
  SET_KATEGORILER_UPDATE(state, payload) {
    let index = state.kategoriler.findIndex((x) => x._id == payload._id);
    state.kategoriler[index] = payload;
  },
  SET_KATEGORILER_DELETE(state, k_no) {
    let altindex = state.kategoriler.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.kategoriler.splice(altindex, 1);
    }
    let index = state.kategoriler.findIndex((x) => x.k_no == k_no);
    state.kategoriler.splice(index, 1);
  },
};
const actions = {
  async kategoriListele({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kategori-listele', data).then((res) => {
      commit('SET_KATEGORILER', res.data.data);
      return res;
    });
  },
  async kategoriEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kategori-ekle', data).then((res) => {
      commit('SET_KATEGORILER_PUSH', res.data.data);
      return res;
    });
  },
  async kategoriGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kategori-guncelle', data).then((res) => {
      commit('SET_KATEGORILER_UPDATE', res.data.data);
      return res;
    });
  },
  async kategoriSil({ commit }, k_no) {
    return await axiosIns.post('/tanimlamalar/kategori-sil', { k_no }).then((res) => {
      commit('SET_KATEGORILER_DELETE', k_no);
      return res;
    });
  },
  async kategoriGorselSil({ commit }, k_no) {
    return await axiosIns.post('/tanimlamalar/kategori-gorsel-sil', { k_no }).then((res) => {
      commit('SET_KATEGORILER_UPDATE', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
