import axiosIns from '@/libs/axios';
const state = {
  sayfalar: [],
};
const getters = {
  getSayfalar: (state) => state.sayfalar,
};
const mutations = {
  SET_SAYFALAR(state, payload) {
    state.sayfalar = payload;
  },
  SET_SAYFALAR_PUSH(state, payload) {
    state.sayfalar.push(payload);
  },
  SET_SAYFALAR_UPDATE(state, payload) {
    let index = state.sayfalar.findIndex((x) => x._id == payload._id);
    state.sayfalar[index] = payload;
  },
  SET_SAYFALAR_DELETE(state, k_no) {
    let index = state.sayfalar.findIndex((x) => x.k_no == k_no);
    state.sayfalar.splice(index, 1);
  },
};
const actions = {
  async sayfaListele({ commit }, data) {
    return await axiosIns.post('/web-yonetim/sayfa-listele', data).then((res) => {
      commit('SET_SAYFALAR', res.data.data);
      return res;
    });
  },
  async sayfaEkle({ commit }, data) {
    return await axiosIns
      .post('/web-yonetim/sayfa-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_SAYFALAR_PUSH', res.data.data);
        return res;
      });
  },
  async sayfaGuncelle({ commit }, data) {
    return await axiosIns
      .post('/web-yonetim/sayfa-guncelle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_SAYFALAR_UPDATE', res.data.data);
        return res;
      });
  },
  async sayfaBannerSil({}, data) {
    return await axiosIns.post('/web-yonetim/sayfa-banner-sil', data).then((res) => {
      return res;
    });
  },
  async sayfaResimSiraGuncelle({}, data) {
    return await axiosIns.post('/web-yonetim/sayfa-resim-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async sayfaResimKapak({ commit }, data) {
    return await axiosIns.post('/web-yonetim/sayfa-resim-kapak', data).then((res) => {
      console.log(res.data.data);
      commit('SET_SAYFALAR_UPDATE', res.data.data);
      return res;
    });
  },
  async sayfaResimSil({}, data) {
    return await axiosIns.post('/web-yonetim/sayfa-resim-sil', data).then((res) => {
      return res;
    });
  },
  async sayfaSil({ commit }, k_no) {
    return await axiosIns.post('/web-yonetim/sayfa-sil', { k_no }).then((res) => {
      commit('SET_SAYFALAR_DELETE', k_no);
      return res;
    });
  },

  async sayfaFindOne({}, k_no) {
    return await axiosIns.post('/web-yonetim/sayfa-find-one', { k_no }).then((res) => {
      if (res.data.success === true) return res.data.data;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
