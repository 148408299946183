import Vue from 'vue';
import moment from 'moment';

Vue.filter('cur', (value) => {
  return parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
});

Vue.filter('moment', (value) => {
  return moment(value).format('DD.MM.YYYY');
});
Vue.filter('momentTime', (value) => {
  return moment(value).format('HH:mm');
});

Vue.filter('momentFull', (value) => {
  return moment(value).format('DD.MM.YYYY HH:mm');
});

Vue.filter('slug', (value) => {
  return value
    .toUpperCase()
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '');
});

Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});

Vue.filter('mask', (value, size = 2) => {
  let result = '';
  let parcala = value.split(' ');
  parcala.forEach((element) => {
    result += element.substr(0, size) + element.substr(2, element.length - size).replace(/./g, '*') + ' ';
  });

  return result;
});

Vue.filter('exchange', (value, sabit_kur_fiyat, secilen_kur_fiyat) => {
  let fiyat = (parseFloat(value) * parseFloat(sabit_kur_fiyat)) / parseFloat(secilen_kur_fiyat);
  return parseFloat(fiyat).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
});
