export default [
  {
    path: '/web-yonetim/sayfa',
    name: 'sayfalar',
    redirect: '/web-yonetim/sayfa/listele',
    component: () => import(/* webpackChunkName: "sayfalar" */ '@/views/webYonetim/sayfalar/index.vue'),
    children: [
      {
        path: '/web-yonetim/sayfa/listele',
        name: 'sayfa-listele',
        component: () => import(/* webpackChunkName: "sayfa-listele" */ '@/views/webYonetim/sayfalar/listele.vue'),
      },
      {
        path: '/web-yonetim/sayfa/ekle',
        name: 'sayfa-ekle',
        component: () => import(/* webpackChunkName: "sayfa-ekle" */ '@/views/webYonetim/sayfalar/sayfaTanimla.vue'),
      },
      {
        path: '/web-yonetim/sayfa/guncelle/:k_no?',
        name: 'sayfa-guncelle',
        component: () => import(/* webpackChunkName: "sayfa-ekle" */ '@/views/webYonetim/sayfalar/sayfaTanimla.vue'),
      },
    ],
  },
  {
    path: '/web-yonetim/slider',
    name: 'slider',
    component: () => import(/* webpackChunkName: "slider" */ '@/views/webYonetim/slider/index.vue'),
  },
  {
    path: '/web-yonetim/galeri',
    name: 'galeri',
    component: () => import(/* webpackChunkName: "slider" */ '@/views/webYonetim/galeri/index.vue'),
  },
  {
    path: '/web-yonetim/iletisim',
    name: 'iletisim',
    component: () => import(/* webpackChunkName: "iletisim" */ '@/views/webYonetim/iletisim/index.vue'),
  },
];
